import qs from 'query-string';
const isDemo = window.location.pathname.startsWith('/demo');
const isMobileApp =
  qs.parse(location.search)['mobileApp'] || sessionStorage.getItem('__sb_mobile_app__');

if (isDemo) {
  import('demo/index');
} else if (isMobileApp) {
  sessionStorage.setItem('__sb_mobile_app__', 'true');
  import('native/index');
} else {
  import('./index');
}
